<template>
  <div>
    <div class="flex flex-col w-full h-auto mb-12">
      <!-- Header -->
      <div class="flex w-full h-auto justify-center items-center">
        <span
          class="Helvetica text-2xl font-bold text-green-500 justify-center normal-case"
          >{{ $t("order_information") }}</span
        >
      </div>
      <div class="flex w-full h-auto justify-center items-center">
        <div class="flex w-full h-auto py-3 justify-end text-sm font-thin px-5">
          <button
            @click="OrderChange"
            class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
          >
            <i class="material-icons">replay</i> Create change Order
          </button>
        </div>
      </div>
      <!-- ******************************** -->
      <div class="px-6">
        <div class="flex bg-white w-full space-x-2">
          <div
            class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
          >
            <div class="flex w-full mt-1">
              <span class="rounded-full h-12 w-12 bg-gray-200">
                <i class="material-icons p-3 text-green-400">person</i>
              </span>

              <div class="ml-5 w-full pb-4">
                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                  {{ $t("customer_information") }}
                </div>
                <div class="text-sm font-light text-gray-500"></div>
                <div class="w-full space-y-4">
                  <div class="flex">
                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                    >
                      <div
                        class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                      >
                        <p>
                          <label
                            for="customerFullName"
                            class="bg-white text-gray-700 px-1"
                            >{{ $t("full_name") }}
                            <span :class="$colors.required">*</span></label
                          >
                        </p>
                      </div>
                      <p>
                        <input
                          :id="'customerFullName' + index"
                          autocomplete="false"
                          tabindex="0"
                          type="text"
                          v-model="item.customer.fullName"
                          class="py-2 text-xs px-2 outline-none block h-full w-full"
                          disabled
                        />
                      </p>
                    </div>
                  </div>

                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerPhone"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("phone") }}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        :id="'customerPhone' + index"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="item.customer.phone"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                        disabled
                      />
                    </p>
                  </div>
                  <div
                    class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="country"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("country") }}</label
                        >
                      </p>
                    </div>
                    <p>
                      <v-select
                        v-model="item.customer.country"
                        :options="options"
                        v-bind:class="{ disabled: true }"
                        label="name"
                      >
                        <template slot="option" slot-scope="option">
                          <img
                            class="flag-img"
                            :src="$f.getCountryCode(option.code)"
                          />
                          {{ option.name }}
                        </template>
                      </v-select>
                    </p>
                  </div>
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="city" class="bg-white text-gray-700 px-1"
                          >{{ $t("city") }}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <v-select
                        @input="setSelectedCity"
                        v-model="item.customer.city"
                        :options="cities"
                      ></v-select>
                    </p>
                  </div>
                  <div
                    v-if="
                      item.customer.city &&
                      feesShipp &&
                      item.customer.country == 'MA'
                    "
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="Frais" class="bg-white text-gray-700 px-1"
                          >{{ $t("shipping_cost") }}
                          <sup v-if="idWharhouse"
                            >({{ idWharhouse.currency }})</sup
                          >
                        </label>
                      </p>
                    </div>
                    <p>
                      <input
                        id="Frais"
                        autocomplete="false"
                        disabled
                        tabindex="0"
                        :value="feesShipp"
                        type="text"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerAddress"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("adress") }}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <textarea
                        :id="'customerAddress' + index"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="item.customer.address"
                        class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                      ></textarea>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ****************************** Order Information *********************************************-->

          <div
            class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
          >
            <div class="flex w-full mt-1">
              <span class="rounded-full h-12 w-12 bg-gray-200">
                <i class="material-icons p-3 text-green-400"
                  >local_grocery_store</i
                >
              </span>

              <div class="ml-5 w-full">
                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                  {{ $t("update_order_information") }}
                </div>

                <div class="w-full space-y-4">
                  <div class="flex">
                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                    >
                      <div
                        class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                      >
                        <p>
                          <label
                            for="status"
                            v-bind:style="{
                              borderColor: item.status.color,
                            }"
                            class="bg-white text-gray-700 px-1"
                            >{{ $t("status") }}
                            <span :class="$colors.required">*</span>
                          </label>
                        </p>
                      </div>
                      <p>
                        <select
                          name="status"
                          autocomplete="type"
                          :disabled="
                            item.status &&
                            (item.status.name === 'OutOfStock' ||
                              item.status.name === 'Confirmed')
                          "
                          v-bind:style="{
                            color: item.status.color,
                            borderColor: item.status.color,
                          }"
                          v-model="item.status"
                          class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                        >
                          <option class="bg-white" selected :value="null">
                            {{ $t("status") }}
                          </option>
                          <option
                            class="bg-white text-gray-600"
                            v-for="(value, index) in statusOrder"
                            :key="index"
                            :value="value"
                          >
                            {{ value.name }}
                          </option>
                        </select>
                      </p>
                    </div>
                  </div>

                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerPhone"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("shipping_address") }}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <textarea
                        v-if="item.status.name == 'Confirmed'"
                        :id="'customerShippingAddress' + index"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="item.customer.shippingAddress"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                        disabled
                      />

                      <textarea
                        v-else
                        :id="'customerShippingAddress' + index"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="item.customer.shippingAddress"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>

                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerPhone"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("comment") }}
                          <span :class="$colors.required"></span
                        ></label>
                      </p>
                    </div>
                    <p>
                      <textarea
                        :id="'comment' + index"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="item.comment"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="
              item.status.name == 'Confirmed' && currentUser.type != 'Seller'
            "
            class="flex w-full justify-between shadow-xs items-center mt-5 h-auto p-6 my-1 mb-1 rounded-lg"
          >
            <div class="flex w-full mt-1">
              <span class="rounded-full h-12 w-12 bg-gray-200">
                <i class="material-icons p-3 text-green-400">local_shipping</i>
              </span>

              <div class="ml-5 w-full pb-4">
                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                  {{ $t("shipping") }}
                </div>
                <div class="grid grid-rows-2 grid-flow-col gap-4">
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerAddress"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("address_shipping")
                          }}<span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <textarea
                        id="customerAddress"
                        type="text"
                        v-model="shipping.address"
                        class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                      ></textarea>
                    </p>
                  </div>

                  <div></div>

                  <div
                    class="border h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="category"
                          class="bg-white text-gray-700 px-1"
                          >Shipping date
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>

                    <p>
                      <datetime-v2
                        type="datetime"
                        v-model="shipping.date"
                        :min-datetime="$moment(Date.now()).format('yyyy-MM-DD')"
                        placeholder="Shipping date ..."
                        input-class="py-2 text-xs px-2 outline-none block h-full w-full"
                      ></datetime-v2>
                    </p>
                    <input
                      type="hidden"
                      name="order"
                      v-model="shipping.order"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--*******************  Product Order ***************************************-->
        <div
          :id="'order-' + item._id"
          class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
        >
          <div class="flex w-full mt-1">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400">local_mall</i>
            </span>

            <div class="ml-5 w-full pb-4">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("order_products") }}
              </div>

              <div class="w-full space-y-4">
                <div class="w-full">
                  <table
                    style="display: inline-table"
                    class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs"
                  >
                    <thead class="text-white">
                      <tr
                        class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                      >
                        <th class="p-2 justify-center">{{ $t("products") }}</th>
                        <th class="p-2 justify-center">{{ $t("quantity") }}</th>
                        <th class="p-2 justify-center">{{ $t("total") }}</th>
                      </tr>
                    </thead>
                    <tbody
                      class="flex-1 sm:flex-none"
                      v-for="it in item.details"
                      :key="it._id"
                    >
                      <tr
                        class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                      >
                        <td
                          class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2"
                        >
                          <div class="flex space-x-10">
                            <div>
                              <img
                                alt="..."
                                :src="
                                  it.product.picture ||
                                  'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                "
                                class="h-16 w-16 rounded-md mx-auto"
                              />
                            </div>
                            <div>
                              {{ it.product.name }}
                              <p class="font-light">
                                {{ $t("price") }} : {{ it.unitPrice }}
                                <sup v-if="idWharhouse"
                                  >({{ idWharhouse.currency }})</sup
                                >
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2 truncate"
                        >
                          {{ it.quantity }}
                        </td>
                        <td
                          class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2 truncate"
                        >
                          {{ it.quantity * it.unitPrice }}
                          <sup v-if="idWharhouse"
                            >({{ idWharhouse.currency }})</sup
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--*******************  Order refondu ***************************************-->
        <div
          v-if="item.status.name == 'Return' && currentUser.type != 'Seller'"
          class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
        >
          <div class="flex w-full mt-1">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400">replay</i>
            </span>

            <div class="ml-5 w-full pb-4">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("order_refunded_products") }}
              </div>

              <div class="w-full space-y-4">
                <div class="w-full">
                  <table
                    style="display: inline-table"
                    class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs"
                  >
                    <thead class="text-white">
                      <tr
                        class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                      >
                        <th class="p-2 justify-center">{{ $t("products") }}</th>
                        <th class="p-2 justify-center">{{ $t("quantity") }}</th>
                        <th class="p-2 justify-center">
                          {{ $t("quantity_returned") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="flex-1 sm:flex-none"
                      v-for="it in item.details"
                      :key="it._id"
                    >
                      <tr
                        class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                      >
                        <td
                          class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2"
                        >
                          <div class="flex space-x-10">
                            <div>
                              <img
                                alt="..."
                                :src="
                                  it.product.picture ||
                                  'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                "
                                class="h-16 w-16 rounded-md mx-auto"
                              />
                            </div>
                            <div>
                              {{ it.product.name }}
                            </div>
                          </div>
                        </td>
                        <td
                          class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
                        >
                          <b>{{ it.quantity }}</b>
                        </td>
                        <td
                          class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2 truncate"
                        >
                          <div
                            class="grid gap-4"
                            :class="
                              CheckQteDefecteuseorderexist
                                ? 'grid-cols-2'
                                : 'grid-cols-1'
                            "
                          >
                            <div
                              class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                            >
                              <b>{{ $t("ok_good") }}</b>
                            </div>
                            <div
                              v-if="CheckQteDefecteuseorderexist"
                              class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                            >
                              <b>{{ $t("defective") }}</b>
                            </div>
                            <input
                              v-model="it.product.qteChange"
                              :disabled="CheckQteDefecteuseorderexist"
                              min="0"
                              :max="it.quantity"
                              type="number"
                              class="border border-green-600 py-2 text-xs px-2 outline-none block h-full w-full"
                            />
                            <div
                              v-if="CheckQteDefecteuseorderexist"
                              class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
                            >
                              <b>{{ it.quantity - it.product.qteChange }}</b>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: { required: true, type: Object },
    item: { default: [] },
    shipping: { type: Object, default: null },
    statusOrder: { type: Array },
    zones: { type: Array },
    cities: { type: Array },
    courier: { type: Array },
    index: { type: Number },
    feesShipp: { type: Number },
    idWharhouse: { type: Object },
    currentUser: { type: Object },
    CheckQteDefecteuseorderexist: { required: false },
  },
  data() {
    return {
      typesShipp: ["normal", "pendingChange", "change"],
      options: this.$countries,
    };
  },
  watch: {
    "item.status"(val) {
      if (val.name === "Confirmed") {
        this.scrollTo(this.item._id);
      }
    },
  },
  async mounted() {
    this.shipping.order = this.item._id;
    await this.CheckQteDefecteuseOrder();
  },
  methods: {
    scrollTo(hash) {
      location.hash = "#order-" + hash;
    },
    getCourierZone: function (val) {
      this.$emit("getCourierZone", val);
    },
    OrderChange: function () {
      this.$emit("OrderChange", this.index, this.item);
    },
    CheckQteDefecteuseOrder: function () {
      this.$emit("CheckQteDefecteuseOrder", this.item);
    },
    setSelectedCity: function (city) {
      if (city) this.$emit("getFeesShippBycity", city);
    },
  },
};
</script>
